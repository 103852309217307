import React from "react"
import Button from "../../Button"
import Styled from "./styled.module.css"
import Divider from "../../../assets/img/careers/divider1.svg"
function Revolution() {
  return (
    <section className={Styled.section} id="section">
      <div className={Styled.hero}>
        <h1 className={`${Styled.heroTitle} xl-title bold`}>
          Join our<br /> odyssey
        </h1>
        <h2 className={Styled.heroSubtitle}>
          Your life is an epic. Let Integra be a pleasant and rewarding chapter in it.<br />
          Join 1400+ professionals across 3 continents.
        </h2>
        <a href="#positions">
          <Button>See open positions</Button>
        </a>
      </div>
      <img className={Styled.divider} alt="Divider" src={Divider}></img>
    </section>
  )
}

export default Revolution
