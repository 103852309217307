import React, { useState } from "react"
import { Link, navigate, StaticQuery, graphql } from "gatsby"

import Styled from "./styled.module.css"
import RightArrow from "../../Icon/Arrow/right-arrow-primary"

function Openings({ setShowModal }) {
  const [isExpand, setActive] = useState("false")

  const toggleMoreJobs = () => {
    setActive(!isExpand)
    if (!isExpand) {
      navigate("/careers#section-sales")
    }
  }

  const totaljobs = 0

  return (
    <section id="section-sales" className={Styled.section}>
      <div className="container">
        <div className={Styled.wrapper}>
          <h2 className={Styled.subTitle}>Sales and marketing job openings</h2>
          <div>
            <table className={Styled.table}>
              <thead>
                <tr>
                  <th>POSITION</th>
                  <th className="hidden-upto-sm">LOCATION</th>
                  <th>POSTED DATE</th>
                </tr>
              </thead>
              <StaticQuery
                query={graphql`
                  {
                    allFile(
                      filter: {
                        sourceInstanceName: { eq: "markdown-pages-sales" }
                      }
                    ) {
                      nodes {
                        childMarkdownRemark {
                          frontmatter {
                            slug
                            title
                            location
                            date(formatString: "DD MMM YYYY")
                          }
                        }
                      }
                    }
                  }
                `}
                render={data => (
                  <tbody className={isExpand ? "hideExpand" : "showExpand"}>
                    {data.allFile.nodes.map(({ childMarkdownRemark }, i) => (
                      <tr
                        className={`job-list ${i > 4 ? "invisble" : ""} ${isExpand ? "" : "loaded"
                          }`}
                        style={{
                          cursor: "pointer",
                        }}
                        key={i}
                        onClick={() =>
                          navigate(`${childMarkdownRemark.frontmatter.slug}`)
                        }
                      >
                        <td className={Styled.position}>
                          <span
                            title={childMarkdownRemark.frontmatter.title}
                            dangerouslySetInnerHTML={{
                              __html: childMarkdownRemark.frontmatter.title,
                            }}
                          ></span>
                          {/* <span className={Styled.responsiveSpan}>
                            Coimbatore - India
                          </span> */}
                        </td>
                        <td
                          className="hidden-upto-sm"
                          dangerouslySetInnerHTML={{
                            __html: childMarkdownRemark.frontmatter.location,
                          }}
                        ></td>
                        <td className={Styled.post}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: childMarkdownRemark.frontmatter.date,
                            }}
                          ></span>
                          <Link to={childMarkdownRemark.frontmatter.slug}>
                            <RightArrow />
                          </Link>
                        </td>
                      </tr>
                    ))}
                    {data.allFile.nodes.length > 4 ? (
                      <tr>
                        <td colSpan="3">
                          <div className={Styled.jobLoadmore}>
                            <div
                              onClick={toggleMoreJobs}
                              className={Styled.jobLoadmoreText}
                            >
                              {isExpand ? "LOAD" : "HIDE"} MORE JOBS
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr></tr>
                    )}
                  </tbody>
                )}
              />
              <tfoot>
                <tr>
                  Didn’t see your dream job?{" "}
                  <a onClick={() => setShowModal(true)}>Drop us your resumé</a>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Openings
