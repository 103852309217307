import React, { useState } from "react"
import Bpo from "./bpo"
import It from "./it"
import Sales from "./sales"
import Form from "./form"

function Openings() {
  const [showModal, setShowModal] = useState(false)

  return (
    <section id="openings">
      <Bpo setShowModal={setShowModal} />
      <It setShowModal={setShowModal} />
      <Sales setShowModal={setShowModal} />
      {showModal ? <Form setShowModal={setShowModal} /> : null}
    </section>
  )
}

export default Openings
