import React from "react"
import Styled from "./styled.module.css"
import Photo from "../../../assets/img/careers/photo-collage.png"

function Good() {
  return (
    <section id="good">
      <div className="container">
        <div className={Styled.wrapper}>
          <img alt="Mission" className={Styled.missionImg} src={Photo}></img>
          <div className={Styled.missionWrapper}>
            <div className={Styled.missionTitleWrapper}>
              <div className={Styled.mission}>We want you to</div>
              <h1 className={Styled.missionTitle}>Be good</h1>
              <h1 className={Styled.missionTitle}>Do good</h1>
              <h1 className={Styled.missionTitle}>Feel good</h1>
            </div>
            <h2 className={Styled.missionSubtitle}>
              We want you to ensure client happiness, treat everyone equally and
              develop trust. We would love to work with you if you have a can-do
              attitude and relentless focus on quality and accountability. You
              should feel good and proud of your achievements and your team.
            </h2>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Good
