import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/"
import Revolution from "../components/Careers/Revolution"
import Good from "../components/Careers/Good"
import Whywork from "../components/Careers/Whywork"
import Lifeat from "../components/Careers/Lifeat"
import Openings from "../components/Careers/Openings"
import "../assets/css/normalize.css"
import "../assets/css/custom.css"
import "../components/Careers/assets/custom.css"
import FeaturedImage from "../assets/img/integra-featured-image.png"

export default function Careers() {
  return (
    <>
      <Helmet
        title="Integra Global Solutions Careers and Job Openings."
        meta={[
          {
            name: `description`,
            content:
              "Looking for a job opening in BPO / KPO industry? Browse through the job openings in this page. We are a company with offices in USA, UK, India and Philippines. Apply Now.",
          },
          {
            name: "keywords",
            content:
              "Open positions, Job openings, careers, offices, openings in branches, BPO job openings, KPO job openings",
          },
        ]}
      >
        <meta property="og:url" content="https://www.globalintegra.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Integra Global Solutions Careers and Job Openings."
        />
        <meta
          property="og:description"
          content="Looking for a job opening in BPO / KPO industry? Browse through the job openings in this page. We are a company with offices in USA, UK, India and Philippines. Apply Now."
        />
        <meta property="og:image" content={FeaturedImage} />

        <meta name="twitter:card" content="summary" />
        <meta property="twitter:domain" content="globalintegra.com" />
        <meta property="twitter:url" content="https://www.globalintegra.com/" />
        <meta
          name="twitter:title"
          content="Integra Global Solutions Careers and Job Openings."
        />
        <meta
          name="twitter:description"
          content="Looking for a job opening in BPO / KPO industry? Browse through the job openings in this page. We are a company with offices in USA, UK, India and Philippines. Apply Now."
        />
        <meta name="twitter:image" content={FeaturedImage}></meta>
      </Helmet>
      <Layout>
        <main>
          <Revolution />
          <Good />
          <Whywork />
          <Lifeat />
          <Openings />
          {/* <Jobalert bgColor="White" /> */}
        </main>
      </Layout>
    </>
  )
}
